import {
  CheckoutDetails,
  ErrorCode,
  OutletListItem,
  SalesLocationType,
  SystemVisibility,
  CheckoutSummary,
  PaymentMethodListItem,
  CheckoutStatus,
  CheckoutCancelActor,
  CheckoutAdyenPaymentResponse,
  OrderFormat,
  CheckoutMetaDataItemPenguinLockersData,
} from '@ancon/wildcat-types'
import { CheckoutRequestItem } from '@ancon/wildcat-utils/checkout/types'
import { PaymentData } from '@adyen/adyen-web/dist/types/components/types'
import isCheckoutItemsAreEqual from '@ancon/wildcat-utils/checkout/isCheckoutItemsAreEqual'
import getCheckoutRequestItemFromCheckoutItem from '@ancon/wildcat-utils/checkout/getCheckoutRequestItemFromCheckoutItem'
import isUpdatingCheckoutAllowed from '@ancon/wildcat-utils/checkout/isUpdatingCheckoutAllowed'
import isDeletingCheckoutAllowed from '@ancon/wildcat-utils/checkout/isDeletingCheckoutAllowed'
import getT from 'next-translate/getT'
import serializeError from '@ancon/wildcat-utils/error/serializeError'
import isAPIError from '@ancon/wildcat-utils/error/isAPIError'
import APIFilterBuilder from '@ancon/wildcat-utils/api/APIFilterBuilder'
import getCheckoutWalletTopUpMetaData from '@ancon/wildcat-utils/checkout/getCheckoutWalletTopUpMetaData'

import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import { OutletFilters } from '../../outlet/types'
import {
  outletFiltersSelector,
  outletListItemSelector,
} from '../../outlet/store/outletSelector'
import {
  clientContextCurrentUserCompanyIdSelector,
  clientContextCustomerIdSelector,
  clientContextCustomerNameSelector,
  clientContextCustomerPhoneNumberSelector,
} from '../../clientContext/store/clientContextSelectors'
import api from '../../../api'
import {
  CheckoutErrorContextType,
  CheckoutFilters,
  CheckoutOutOfStockAdditionalData,
  LocalStorageCartMeta,
  CheckoutInvoicePaymentResponse,
  CheckoutInvalidDataErrorAdditionalData,
} from '../types'
import { outletSetOutletFilters } from '../../outlet/store/outletSlice'
import localStorageUtils from '../../app/utils/localStorageUtils'
import getCheckoutErrorModalConfig from '../utils/getCheckoutErrorModalConfig'
import { appShowError } from '../../app/store/appSlice'
import { ErrorModalType } from '../../app/types'
import getLocaleTag from '../../app/utils/getLocalTag'
import { appLanguageSelector } from '../../app/store/appSelectors'
import getTenantIds from '../../app/utils/getTenantIds'
import archiveLogger from '../../../utils/archiveLogger'
import getCheckoutMetaData from '../utils/getCheckoutMetaData'
import getCheckoutFiltersFromMetaData from '../utils/getCheckoutFiltersFromMetaData'
import { AOWAllOrderFormats } from '../../app/constants'
import { AsSoonAsPossibleServiceTime } from '../constants'
import { AdyenPaymentMethodsResponse } from '../../adyen/types'

import {
  checkoutCurrentCheckoutDetailsSelector,
  checkoutCurrentCheckoutIdSelector,
  checkoutCurrentCheckoutMetaDataSelector,
  checkoutCurrentCheckoutOutletIdSelector,
  checkoutCurrentCheckoutTipAmountSelector,
  checkoutCurrentCheckoutTotalAmountCurrencySelector,
  checkoutFiltersSelector,
  checkoutIdSelector,
  checkoutIsWalletTopUpSelector,
  checkoutItemsSelector,
  checkoutOrderInstructionsSelector,
  checkoutSummaryCheckoutIdSelector,
  checkoutSummaryOutletIdSelector,
  generalCheckoutCompanyIdSelector,
  outletSelectedOutletHasCheckoutSelector,
} from './checkoutSelectors'

export const updateCheckoutPenguinLockersMetadata = createAppAsyncThunk<
  void,
  {
    checkoutId: string
    outletId: string
    metadata: CheckoutMetaDataItemPenguinLockersData
  }
>(
  'checkout/updateCheckoutPenguinLockersMetadata',
  async ({ checkoutId, outletId, metadata }) => {
    await api.core.checkouts.put.penguinLockersMetadata(
      { penguinLockersMetadata: metadata },
      { outletId, checkoutId },
    )
  },
)

export const createCheckout = createAppAsyncThunk<
  {
    outletLisItem?: OutletListItem | null
    checkoutId: string
    checkoutFilters: CheckoutFilters
  } | void,
  {
    items: CheckoutRequestItem[]
    outletId: string
    filters?: Partial<OutletFilters>
  }
>(
  'checkout/createCheckout',
  async (
    { items = [], outletId, filters },
    { getState, rejectWithValue, dispatch },
  ) => {
    const locale = appLanguageSelector(getState())
    const outletFilters = outletFiltersSelector(getState())
    const customerId = clientContextCustomerIdSelector(getState())
    const companyId = clientContextCurrentUserCompanyIdSelector(getState())
    const outletLisItem = outletListItemSelector(getState())
    const checkoutFilters = (filters || outletFilters) as CheckoutFilters
    const t = await getT(locale, 'common')

    try {
      const response = await api.core.checkouts.post.create(
        {
          orderFormat: checkoutFilters.orderFormat!,
          serviceTime:
            checkoutFilters.serviceTime || AsSoonAsPossibleServiceTime,
          salesLocation: {
            id: checkoutFilters.salesLocation?.table?.id,
            type: checkoutFilters.salesLocation?.type || SalesLocationType.None,
          },
          delivery: checkoutFilters.delivery,
          customerId,
          companyId,
          items,
          instructions: outletLisItem?.enableCustomOrderNotesInAO
            ? checkoutFilters.delivery?.address?.note
            : undefined,
        },
        { outletId },
      )

      const checkoutMetadata = getCheckoutMetaData(checkoutFilters)

      const penguinLockersMetaData =
        checkoutMetadata as CheckoutMetaDataItemPenguinLockersData

      if (penguinLockersMetaData?.station) {
        await dispatch(
          updateCheckoutPenguinLockersMetadata({
            checkoutId: response.data.id,
            outletId,
            metadata: penguinLockersMetaData,
          }),
        )
      }

      localStorageUtils.setItem<LocalStorageCartMeta>('cartMeta', {
        outletId,
        checkoutId: response!.data.id,
      })

      return { checkoutId: response!.data.id, outletLisItem, checkoutFilters }
    } catch (err) {
      const errorModalConfig = await getCheckoutErrorModalConfig(
        err,
        {
          title: t('errors.checkoutGenericCreateFailure.title'),
          message: t('errors.checkoutGenericCreateFailure.message'),
        },
        CheckoutErrorContextType.CreateCheckout,
        undefined,
        locale,
        { orderFormat: checkoutFilters.orderFormat! },
      )
      dispatch(
        appShowError(
          errorModalConfig
            ? {
                errorModalType: ErrorModalType.GeneralCheckoutError,
                ...errorModalConfig,
              }
            : undefined,
        ),
      )
      return rejectWithValue(errorModalConfig)
    }
  },
)

export const fetchCurrentCheckoutDetails = createAppAsyncThunk<
  CheckoutDetails | null,
  { outletId?: string; checkoutId?: string } | undefined
>(
  'checkout/fetchCurrentCheckoutDetails',
  async (data, { getState, rejectWithValue, dispatch }) => {
    const locale = appLanguageSelector(getState())
    const t = await getT(locale, 'common')

    try {
      const checkoutOutletId =
        data?.outletId || checkoutCurrentCheckoutOutletIdSelector(getState())!
      const currentCheckoutId =
        data?.checkoutId || checkoutCurrentCheckoutIdSelector(getState())!

      const response = await api.core.checkouts.get.details({
        outletId: checkoutOutletId,
        checkoutId: currentCheckoutId,
      })

      return response?.data ?? null
    } catch (err) {
      const errorModalConfig = await getCheckoutErrorModalConfig(
        err,
        {
          title: t('errors.checkoutGenericFetchDetailsFailure.title'),
          message: t('errors.checkoutGenericFetchDetailsFailure.message'),
          errorModalType: ErrorModalType.CheckoutFetchError,
        },
        undefined,
        undefined,
        locale,
      )
      dispatch(
        appShowError(
          errorModalConfig
            ? {
                errorModalType: ErrorModalType.CheckoutFetchError,
                ...errorModalConfig,
              }
            : undefined,
        ),
      )
      localStorageUtils.removeItem('cartMeta')

      return rejectWithValue(errorModalConfig)
    }
  },
)

export const updateCheckout = createAppAsyncThunk<
  void,
  Partial<{
    items: CheckoutRequestItem[]
    instructions: string
    companyId: string | null
    checkoutErrorContextType: CheckoutErrorContextType
  }>
>(
  'checkout/updateCheckout',
  async (
    {
      items,
      instructions,
      companyId: externalCompanyId,
      checkoutErrorContextType = CheckoutErrorContextType.UpdateCheckout,
    },
    { getState, dispatch, rejectWithValue },
  ) => {
    const outletId = checkoutCurrentCheckoutOutletIdSelector(getState())!
    const checkoutId = checkoutIdSelector(getState())!
    const customerId = clientContextCustomerIdSelector(getState())
    const checkoutCompanyId = generalCheckoutCompanyIdSelector(getState())
    const checkoutFilters = checkoutFiltersSelector(getState())
    const checkoutItems = checkoutItemsSelector(getState())!
    const checkoutMetaData = checkoutCurrentCheckoutMetaDataSelector(getState())
    const checkoutInstructions = checkoutOrderInstructionsSelector(getState())
    const customerFullName = clientContextCustomerNameSelector(getState())
    const customerPhoneNumber =
      clientContextCustomerPhoneNumberSelector(getState())
    const isWalletTopUpCheckout = checkoutIsWalletTopUpSelector(getState())
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'common')

    const delivery = checkoutFilters.delivery
      ? {
          ...checkoutFilters.delivery,
          recipient: customerFullName,
          phone: customerPhoneNumber!,
        }
      : null

    if (!outletId || !checkoutId) {
      return undefined
    }

    const walletsMetaData = isWalletTopUpCheckout
      ? getCheckoutWalletTopUpMetaData({
          items: checkoutItems,
          metaData: checkoutMetaData!,
        })
      : undefined

    const attendeeItems = [
      {
        attendeeId: null,
        items:
          items ||
          checkoutItems.map(item =>
            getCheckoutRequestItemFromCheckoutItem(item, walletsMetaData),
          ),
      },
    ]

    /**
     * The `externalCompanyId` can be null.
     * So, use `externalCompanyId` if it's provided, otherwise send the checkout company id.
     */
    const companyId =
      externalCompanyId === undefined ? checkoutCompanyId : externalCompanyId

    const metadata = getCheckoutMetaData(checkoutFilters, checkoutMetaData)

    try {
      const response = await api.core.checkouts.put.update(
        {
          customerId,
          companyId,
          delivery,
          orderFormat: checkoutFilters.orderFormat!,
          serviceTime: checkoutFilters.serviceTime!,
          salesLocation: {
            id: checkoutFilters.salesLocation?.table?.id,
            type: checkoutFilters.salesLocation?.type || SalesLocationType.None,
          },
          attendeeItems,
          instructions: instructions ?? checkoutInstructions,
          metaData: metadata ? JSON.stringify(metadata) : null,
        },
        {
          outletId,
          checkoutId,
        },
      )

      dispatch(fetchCurrentCheckoutDetails({ outletId, checkoutId }))

      if (response && !!response?.data?.items?.length) {
        const { items: responseItems } = response.data
        // Show price change modal
        const errorModalConfig = await getCheckoutErrorModalConfig(
          {
            response: {
              data: {
                additionalData: responseItems,
                errorCode: ErrorCode.CheckoutPriceChanged,
              },
            },
          },
          {
            title: t('errors.checkoutPaymentFailure.title'),
            message: t('errors.unknown.message'),
          },
          undefined,
          undefined,
          locale,
        )
        dispatch(
          appShowError(
            errorModalConfig
              ? {
                  errorModalType: ErrorModalType.CheckoutItemPriceChanged,
                  ...errorModalConfig,
                }
              : undefined,
          ),
        )
      }

      return undefined
    } catch (err: any) {
      // Update outlet filters to the checkout filters
      const outletSelectedOutletHasCheckout =
        outletSelectedOutletHasCheckoutSelector(getState())
      const checkoutDetails = checkoutCurrentCheckoutDetailsSelector(getState())

      if (checkoutDetails && outletSelectedOutletHasCheckout) {
        const metaDataFilters = getCheckoutFiltersFromMetaData(checkoutDetails)

        dispatch(
          outletSetOutletFilters({
            orderFormat: checkoutDetails.orderFormat,
            serviceTime: checkoutDetails.serviceTime,
            delivery: checkoutDetails.delivery || undefined,
            salesLocation: checkoutDetails.salesLocation,
            section: metaDataFilters?.section,
          }),
        )
      }

      const errorModalConfig = await getCheckoutErrorModalConfig(
        err,
        {
          title: t('errors.checkoutGenericUpdateFailure.title'),
          message: t('errors.checkoutGenericUpdateFailure.message'),
        },
        checkoutErrorContextType,
        undefined,
        locale,
        { orderFormat: checkoutFilters.orderFormat! },
      )

      const isStockError =
        err?.response?.data?.errorCode === ErrorCode.PaymentAcceptOutOfStock

      if (isStockError) {
        const additionalData: CheckoutOutOfStockAdditionalData[] =
          err.response?.data?.additionalData || []

        if (
          checkoutErrorContextType !== CheckoutErrorContextType.UpdateCheckout
        ) {
          dispatch(
            appShowError(
              errorModalConfig
                ? {
                    errorModalType: ErrorModalType.GeneralCheckoutError,
                    ...errorModalConfig,
                    additionalData,
                  }
                : undefined,
            ),
          )
        }

        return rejectWithValue({
          ...errorModalConfig,
          stockErrorAdditionalData: additionalData,
        })
      }

      if (
        isAPIError(err, ErrorCode.RequestDataNotValid) &&
        checkoutFilters.orderFormat === OrderFormat.BoxPickup &&
        checkoutErrorContextType === CheckoutErrorContextType.UpdateCheckout
      ) {
        const additionalData = err.response?.data
          ?.additionalData as CheckoutInvalidDataErrorAdditionalData[]

        dispatch(
          appShowError(
            errorModalConfig
              ? {
                  errorModalType:
                    ErrorModalType.CheckoutBoxPickupUnAvailableProducts,
                  ...errorModalConfig,
                  additionalData,
                }
              : undefined,
          ),
        )

        return rejectWithValue({
          ...errorModalConfig,
          invalidDataErrorAdditionalData: additionalData,
        })
      }

      dispatch(
        appShowError(
          errorModalConfig
            ? {
                errorModalType: ErrorModalType.GeneralCheckoutError,
                ...errorModalConfig,
              }
            : undefined,
        ),
      )

      return rejectWithValue(errorModalConfig)
    }
  },
)

export const addCheckoutItem = createAppAsyncThunk<
  void,
  {
    items: CheckoutRequestItem[]
    outletId?: string
    filters?: Partial<OutletFilters>
  }
>(
  'checkout/addCheckoutItem',
  async (
    { items, outletId: fallbackOutletId, filters },
    { getState, dispatch },
  ) => {
    const checkoutOutletId = checkoutCurrentCheckoutOutletIdSelector(getState())
    const checkoutId = checkoutIdSelector(getState())
    const checkoutItems = checkoutItemsSelector(getState())

    const outletId = checkoutOutletId || fallbackOutletId

    if (!checkoutId) {
      await dispatch(
        createCheckout({ items, outletId: outletId!, filters }),
      ).unwrap()
    } else {
      const modifiedCheckoutItems = checkoutItems.reduce<CheckoutRequestItem[]>(
        (acc, checkoutItem) => {
          const checkoutRequestItem =
            getCheckoutRequestItemFromCheckoutItem(checkoutItem)

          items.every((newItem, index) => {
            if (isCheckoutItemsAreEqual(checkoutRequestItem, newItem)) {
              checkoutRequestItem.quantity += newItem.quantity
              items.splice(index, 1)
              return false
            }
            return true
          })

          acc.push(checkoutRequestItem)

          return acc
        },
        [],
      )

      await dispatch(
        updateCheckout({
          items: [...modifiedCheckoutItems, ...items],
          checkoutErrorContextType: CheckoutErrorContextType.AddCheckoutItem,
        }),
      ).unwrap()
    }

    await dispatch(fetchCurrentCheckoutDetails())
  },
)

export const updateCheckoutItem = createAppAsyncThunk<
  void,
  {
    item: CheckoutRequestItem
    checkoutItemId: string
    outletId?: string
    filters?: Partial<OutletFilters>
  }
>(
  'checkout/updateCheckoutItem',
  async (
    { item, checkoutItemId, outletId: fallbackOutletId, filters },
    { getState, dispatch },
  ) => {
    const checkoutOutletId = checkoutCurrentCheckoutOutletIdSelector(getState())
    const checkoutId = checkoutIdSelector(getState())
    const checkoutItems = checkoutItemsSelector(getState())

    const outletId = checkoutOutletId || fallbackOutletId

    if (!checkoutId) {
      await dispatch(
        createCheckout({ items: [item], outletId: outletId!, filters }),
      ).unwrap()
    } else {
      const modifiedCheckoutItems = checkoutItems.reduce<CheckoutRequestItem[]>(
        (acc, checkoutItem) => {
          if (checkoutItem.id === checkoutItemId) {
            acc.push(item)
            return acc
          }

          const checkoutRequestItem =
            getCheckoutRequestItemFromCheckoutItem(checkoutItem)

          acc.push(checkoutRequestItem)

          return acc
        },
        [],
      )

      await dispatch(
        updateCheckout({
          items: modifiedCheckoutItems,
          checkoutErrorContextType: CheckoutErrorContextType.UpdateCheckout,
        }),
      ).unwrap()
    }

    await dispatch(fetchCurrentCheckoutDetails())
  },
)

export const deleteCheckout = createAppAsyncThunk<void>(
  'checkout/deleteCheckout',
  async (_, { getState, dispatch }) => {
    const locale = appLanguageSelector(getState())
    const outletId = checkoutCurrentCheckoutOutletIdSelector(getState())!
    const checkoutId = checkoutIdSelector(getState())!
    const checkoutDetails = checkoutCurrentCheckoutDetailsSelector(getState())

    const t = await getT(locale, 'common')

    try {
      // Skip making request if payment started, it would fail
      if (!checkoutDetails || isDeletingCheckoutAllowed(checkoutDetails)) {
        await api.core.checkouts.delete.deleteCheckout(undefined, {
          outletId,
          checkoutId,
        })
      }

      localStorageUtils.removeItem('cartMeta')
    } catch (err) {
      if (isAPIError(err, ErrorCode.DeleteCheckoutWhenPaymentStarted)) {
        // Ignore payment started error
        localStorageUtils.removeItem('cartMeta')
        return
      }

      const errorModalConfig = await getCheckoutErrorModalConfig(
        err,
        {
          title: t('errors.checkoutGenericDeleteFailure.title'),
          message: t('errors.checkoutGenericDeleteFailure.message'),
        },
        undefined,
        undefined,
        locale,
      )
      dispatch(
        appShowError(
          errorModalConfig
            ? {
                errorModalType: ErrorModalType.GeneralCheckoutError,
                ...errorModalConfig,
              }
            : undefined,
        ),
      )
    }
  },
)

export const generalCheckoutApplyDiscount = createAppAsyncThunk<
  void,
  { code: string }
>(
  'checkout/generalCheckoutApplyDiscount',
  async ({ code }, { getState, dispatch, rejectWithValue }) => {
    const outletId = checkoutCurrentCheckoutOutletIdSelector(getState())!
    const checkoutId = checkoutCurrentCheckoutIdSelector(getState())!
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'common')

    try {
      await api.core.checkouts.put.applyDiscount(
        { code },
        { outletId, checkoutId },
      )
      dispatch(fetchCurrentCheckoutDetails())

      return undefined
    } catch (err) {
      const discountError = await getCheckoutErrorModalConfig(
        err,
        {
          title: t('errors.discountGenericError.title'),
          message: t('errors.discountGenericError.message'),
        },
        undefined,
        undefined,
        locale,
      )

      return rejectWithValue(discountError)
    }
  },
)

export const changeCheckoutItemQuantity = createAppAsyncThunk<
  void,
  { checkoutItemId: string; quantity: number }
>(
  'checkout/changeCheckoutItemQuantity',
  async ({ checkoutItemId, quantity }, { getState, dispatch }) => {
    const checkoutItems = checkoutItemsSelector(getState())!

    const modifiedCheckoutRequestItems = checkoutItems.map(item => {
      const checkoutRequestItem = getCheckoutRequestItemFromCheckoutItem(item)

      if (item.id === checkoutItemId) {
        checkoutRequestItem.quantity = quantity
      }

      return checkoutRequestItem
    })

    await dispatch(
      updateCheckout({
        items: modifiedCheckoutRequestItems,
        checkoutErrorContextType:
          CheckoutErrorContextType.ChangeCheckoutItemQuantity,
      }),
    ).unwrap()

    dispatch(fetchCurrentCheckoutDetails())
  },
)

export const fetchCheckoutOutletListItem = createAppAsyncThunk<
  OutletListItem | null,
  { outletId?: string }
>('checkout/fetchCheckoutOutletListItem', async ({ outletId }) => {
  try {
    const tenantIds = getTenantIds()

    const apiFilterBuilder = new APIFilterBuilder()

    if (tenantIds?.length) {
      apiFilterBuilder.addProperty('tenant.id').in(tenantIds)
    }

    const response = await api.core.outlets.get.nearby({
      outletId,
      orderFormats: AOWAllOrderFormats,
      systemVisibility: SystemVisibility.OrderWeb,
      filter: apiFilterBuilder.build(),
    })

    return response?.data?.items[0] ?? null
  } catch (error) {
    return null
  }
})

export const validateCheckout = createAppAsyncThunk<
  { checkoutId?: string; outletId?: string } | undefined,
  { checkoutId?: string; outletId?: string }
>(
  'checkout/validateCheckout',
  async ({ checkoutId, outletId }, { getState, dispatch, rejectWithValue }) => {
    function getCheckoutIdAndOutletId() {
      const internalCheckoutId = checkoutCurrentCheckoutIdSelector(getState())
      const internalOutletId =
        checkoutCurrentCheckoutOutletIdSelector(getState())

      if (internalCheckoutId && internalOutletId) {
        return {
          currentCheckoutId: internalCheckoutId,
          checkoutOutletId: internalOutletId,
        }
      }

      const checkout =
        localStorageUtils.getItem<LocalStorageCartMeta>('cartMeta')
      if (checkout?.checkoutId && checkout?.outletId) {
        return {
          currentCheckoutId: checkout.checkoutId,
          checkoutOutletId: checkout.outletId,
        }
      }

      return {
        currentCheckoutId: undefined,
        checkoutOutletId: undefined,
      }
    }

    // Skip checkout validation if checkoutId and outletId are not exist
    const { currentCheckoutId, checkoutOutletId } = getCheckoutIdAndOutletId()
    if (!currentCheckoutId && !checkoutOutletId) {
      return rejectWithValue({})
    }

    try {
      const checkoutDetails = await dispatch(
        fetchCurrentCheckoutDetails({
          outletId: outletId || checkoutOutletId,
          checkoutId: checkoutId || currentCheckoutId,
        }),
      ).unwrap()

      if (!checkoutDetails) {
        // TODO:  handle error - checkout not found
        localStorageUtils.removeItem('cartMeta')
        return rejectWithValue({})
      }

      const outletListItem = await dispatch(
        fetchCheckoutOutletListItem({
          outletId: checkoutOutletId ?? undefined,
        }),
      ).unwrap()
      if (!outletListItem || outletListItem.id !== checkoutOutletId) {
        // TODO:  handle error - checkout outlet not found
        rejectWithValue({})
      }

      if (isUpdatingCheckoutAllowed(checkoutDetails)) {
        await dispatch(updateCheckout({})).unwrap()
      }
    } catch (err) {
      // TODO:  handle error
      return rejectWithValue(err)
    }
    return { checkoutId: currentCheckoutId, outletId: checkoutOutletId }
  },
)

export const deleteCheckoutItem = createAppAsyncThunk<
  { isCheckoutDeleted?: boolean },
  { checkoutItemId: string }
>(
  'checkout/deleteCheckoutItem',
  async ({ checkoutItemId }, { getState, dispatch }) => {
    const checkoutItems = checkoutItemsSelector(getState())!

    const modifiedCheckoutRequestItems = checkoutItems.reduce(
      (acc, currentItem) => {
        if (currentItem.id !== checkoutItemId) {
          const checkoutRequestItem =
            getCheckoutRequestItemFromCheckoutItem(currentItem)
          acc.push(checkoutRequestItem)
        }

        return acc
      },
      [] as CheckoutRequestItem[],
    )

    if (modifiedCheckoutRequestItems.length === 0) {
      await dispatch(deleteCheckout()).unwrap()

      return { isCheckoutDeleted: true }
    }
    await dispatch(
      updateCheckout({
        items: modifiedCheckoutRequestItems,
        checkoutErrorContextType:
          CheckoutErrorContextType.ChangeCheckoutItemQuantity,
      }),
    ).unwrap()

    return {}
  },
)

export const fetchCheckoutSummary = createAppAsyncThunk<
  {
    checkoutSummary: CheckoutSummary
    outlet: OutletListItem
    timestamp: string
  },
  { outletId: string; checkoutId: string; isBackgroundFetch?: boolean }
>(
  'checkout/fetchCheckoutSummary',
  async ({ checkoutId, outletId }, { dispatch, getState, rejectWithValue }) => {
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'common')

    try {
      const checkoutResponse = await api.core.checkouts.get.summary({
        outletId,
        checkoutId,
      })

      const checkoutSummary = checkoutResponse!.data

      const outletResponse = await api.core.outlets.get.nearby({
        systemVisibility: SystemVisibility.OrderApp,
        orderFormats: AOWAllOrderFormats,
        limit: 1,
        offset: 0,
        outletId,
      })

      const outlet = outletResponse!.data.items[0]

      return {
        checkoutSummary,
        outlet,
        timestamp: checkoutResponse.headers.date,
      }
    } catch (err) {
      const errorModalConfig = await getCheckoutErrorModalConfig(
        err,
        {
          title: t('errors.checkoutGenericFetchDetailsFailure.title'),
          message: t('errors.checkoutGenericFetchDetailsFailure.message'),
          errorModalType: ErrorModalType.CheckoutFetchError,
        },
        undefined,
        undefined,
        locale,
      )

      dispatch(
        appShowError(
          errorModalConfig
            ? {
                errorModalType: ErrorModalType.CheckoutFetchError,
                ...errorModalConfig,
              }
            : undefined,
        ),
      )

      return rejectWithValue(errorModalConfig)
    }
  },
)

export const fetchCheckoutAdyenPaymentMethods =
  createAppAsyncThunk<AdyenPaymentMethodsResponse>(
    'checkout/fetchCheckoutAdyenPaymentMethods',
    async (_, { getState, rejectWithValue }) => {
      const language = appLanguageSelector(getState())

      const outletId = checkoutCurrentCheckoutOutletIdSelector(getState())!
      const checkoutId = checkoutCurrentCheckoutIdSelector(getState())!

      try {
        const response = await api.core.payments.post.paymentMethods(
          {
            channel: 'web',
            shopperLocale: getLocaleTag(language).replace('-', '_'),
          },
          {
            outletId,
            checkoutId,
          },
        )

        return response.data
      } catch (err: any) {
        return rejectWithValue(serializeError(err))
      }
    },
  )

export const createCheckoutAdyenPayment = createAppAsyncThunk<
  CheckoutAdyenPaymentResponse,
  {
    data: PaymentData
    correlationId: string
  }
>(
  'checkout/createCheckoutAdyenPayment',
  async ({ data, correlationId }, { getState, rejectWithValue }) => {
    const outletId = checkoutCurrentCheckoutOutletIdSelector(getState())!
    const companyId = clientContextCurrentUserCompanyIdSelector(getState())
    const checkoutId = checkoutCurrentCheckoutIdSelector(getState())!
    const tipAmount = checkoutCurrentCheckoutTipAmountSelector(getState())
    const currency =
      checkoutCurrentCheckoutTotalAmountCurrencySelector(getState())

    // Sent to archive service
    const debugInfo = {
      CheckoutId: checkoutId,
      OutletId: outletId,
      CorrelationId: correlationId,
      Method: 'Adyen Checkout',
    }

    archiveLogger.debug('Payment started', debugInfo)

    try {
      const response = await api.core.payments.post.createAdyenPayment(
        {
          paymentData: data,
          ...(companyId && { companyId }),
          ...(tipAmount && {
            tipAmount: {
              amount: tipAmount,
              currency,
            },
          }),
        },
        {
          outletId,
          checkoutId,
        },
        {
          'x-channel': 'web',
          'x-correlation-id': correlationId,
        },
      )
      return response!.data
    } catch (err: any) {
      const isStockError =
        err?.response?.data?.errorCode === ErrorCode.PaymentAcceptOutOfStock

      if (isStockError) {
        const additionalData: CheckoutOutOfStockAdditionalData[] =
          err.response?.data?.additionalData || []

        return rejectWithValue({
          ...serializeError(err),
          stockErrorAdditionalData: additionalData,
        })
      }

      return rejectWithValue(serializeError(err))
    }
  },
)

export const createInvoicePayment = createAppAsyncThunk<
  CheckoutInvoicePaymentResponse,
  {
    invoiceReferenceNote: string
    correlationId: string
  }
>(
  'checkout/createInvoicePayment',
  async (
    { invoiceReferenceNote, correlationId },
    { getState, rejectWithValue },
  ) => {
    const outletId = checkoutCurrentCheckoutOutletIdSelector(getState())!
    const companyId = clientContextCurrentUserCompanyIdSelector(getState())!
    const checkoutId = checkoutCurrentCheckoutIdSelector(getState())!

    // Sent to archive service
    const debugInfo = {
      CheckoutId: checkoutId,
      OutletId: outletId,
      CorrelationId: correlationId,
      Method: 'Invoice Checkout',
    }

    archiveLogger.debug('Payment started', debugInfo)

    try {
      const response = await api.core.payments.post.createInvoicePayment(
        {
          companyId,
          invoiceReferenceNote,
        },
        {
          outletId,
          checkoutId,
        },
        {
          'x-correlation-id': correlationId,
        },
      )

      return response!.data
    } catch (err: any) {
      const isStockError =
        err?.response?.data?.errorCode === ErrorCode.PaymentAcceptOutOfStock

      if (isStockError) {
        const additionalData: CheckoutOutOfStockAdditionalData[] =
          err.response?.data?.additionalData || []

        return rejectWithValue({
          ...serializeError(err),
          stockErrorAdditionalData: additionalData,
        })
      }

      return rejectWithValue(serializeError(err))
    }
  },
)

export const submitCheckoutAdyenDetails = createAppAsyncThunk<
  CheckoutAdyenPaymentResponse | undefined,
  {
    data: PaymentData
    correlationId: string
    outletId: string
    checkoutId: string
  }
>(
  'checkout/submitCheckoutAdyenDetails',
  async (
    { data, correlationId, outletId, checkoutId },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.core.payments.post.details(
        data,
        {
          outletId,
          checkoutId,
        },
        {
          'x-channel': 'web',
          'x-correlation-id': correlationId,
        },
      )

      // Bug: Core can respond with 423 and no data
      return response?.data
    } catch (err: any) {
      return rejectWithValue(serializeError(err))
    }
  },
)

export const cancelGeneralCheckout = createAppAsyncThunk<void>(
  'checkout/cancelGeneralCheckout',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const checkoutId = checkoutSummaryCheckoutIdSelector(getState())!
    const outletId = checkoutSummaryOutletIdSelector(getState())!
    const cancelActorId = clientContextCustomerIdSelector(getState())!
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'common')

    try {
      await api.core.checkouts.put.cancel(
        { cancelActor: CheckoutCancelActor.Customer, cancelActorId },
        { outletId, checkoutId },
      )
      return undefined
    } catch (err) {
      const errorModalConfig = await getCheckoutErrorModalConfig(
        err,
        {
          title: t('errors.checkoutGenericCancelFailure.title'),
          message: t('errors.checkoutGenericCancelFailure.message'),
        },
        undefined,
        undefined,
        locale,
      )
      dispatch(
        appShowError(
          errorModalConfig
            ? {
                errorModalType: ErrorModalType.GeneralCheckoutError,
                ...errorModalConfig,
              }
            : undefined,
        ),
      )
      return rejectWithValue(errorModalConfig)
    }
  },
)

export const fetchCheckoutWayOfPayments = createAppAsyncThunk<
  PaymentMethodListItem[]
>('checkout/fetchCheckoutWayOfPayments', async (_, { getState }) => {
  const outletId = checkoutCurrentCheckoutOutletIdSelector(getState())!

  const response = await api.core.checkouts.get.wayOfPayments({
    outletId,
  })

  return response!.data.wayOfPayments || []
})

export const fetchCheckoutStatus = createAppAsyncThunk<
  { status: CheckoutStatus; timestamp: string },
  {
    outletId: string
    checkoutId: string
  }
>('checkout/fetchCheckoutStatus', async ({ outletId, checkoutId }) => {
  const response = await api.core.checkouts.get.status({
    outletId,
    checkoutId,
  })

  const timestamp = response.headers.date

  return {
    status: response.data.status,
    timestamp,
  }
})
